import * as init from "./initializers.js";
import * as fn from "./functions.js";

export function failed(event, data){
  $.each(data.responseJSON.fields, function(key, value){
    $('.plannerwall-modal-form *[name="project['+key+']"]').parents('.plannerwall-modal-form__field-container').addClass('plannerwall-modal-form__field-container--error');
    $('.plannerwall-modal-form *[name="project['+key+']"]').addClass('plannerwall-modal-form__field--error').siblings('.plannerwall-modal-form__field-label').addClass('plannerwall-modal-form__field-label--error');
  })

  $('.plannerwall-moda-form__notice-container').html('<span class="plannerwall-modal-form__notice plannerwall-modal-form__notice--error">'+data.responseJSON.error_message+'</span>');
}

export function success(event, data){
  console.log(event, data)
  if(data.action === "destroy"){
    success_destroy(data);
  }else if(data.action === "create"){
    success_create(data);
  }else{
    success_edit(data);
  }

  if(data.action !== "destroy"){
    $.when( open_project(data.urls.edit) ).then(function(){
      success_message(data.message);

      window.project_sort.destroy();
      window.personnel_sort.destroy();
      window.vehicles_sort.destroy();

      window.project_sort = init.sort_projects();
      window.personnel_sort = init.sort_personnel();
      window.vehicles_sort = init.sort_vehicles();
    });
  }

  update_resources($('.plannerwall-week__item[data-date="'+data.item.date+'"]'))
}

export function success_destroy(data){
  fn.hide_project();
  fn.hide_project_card(data);

  if (data.removed.length > 0) {
    $.each(data.removed, function(key, child){
      child.item = child
      fn.hide_project_card(child);
    })
  }
}

export function success_create(data){
  $.when(new_project_card(data.urls.show)).then(function(){
    update_resources(item.parents('.plannerwall-week__item'));
  });

  $.each(data.children, function(key, child){
    $.when(new_project_card(child.urls.show)).then(function(){
      update_resources(item.parents('.plannerwall-week__item'));
    })
  })
}

export function success_edit(data){
  if(data.removed != null && data.removed.length > 0){
    $.each(data.removed, function(index, item){
      item.item = item
      fn.hide_project_card(item);
    })
  }
  if(data.date_span != null && data.date_span.length > 0){
    $.each(data.date_span, function(index, item){
      update_project_card(item.urls.show, item);
    })
  }else{
    update_project_card(data.urls.show, data.old_item);
  }
}

export function success_message(message){
  $('.plannerwall-moda-form__notice-container').html('<span class="plannerwall-modal-form__notice plannerwall-modal-form__notice--success animated fadeIn">'+message+'</span>');
}

export function open_project(target=false, new_project = false){
  let link;
  let data = new FormData();
  let project_container = false

  if (target instanceof Element){
    link = $(target).data('remote-url') + "?date="+ $(target).attr('data-date');
    project_container = $(target).attr('data-project-container');
  }else{
    link = target
  }


  return fetch(link, {
    method: 'get', 
    credentials: "same-origin",
    contentType:'application/json'
  })
  .then(response => response.json())
  .then(response => {
    $('.plannerwall-modal').html(response.html);

    if (project_container) {
      $('.plannerwall-modal select[name="project[project_container_id]"]').val(parseInt(project_container));
    }

    init.projectModal();

    $('.plannerwall-modal').attr('class', 'plannerwall-modal plannerwall-modal--project');
    if(response.item){
      $('.plannerwall-modal').addClass('plannerwall-modal--' + response.item.project_type + '-type');

      if (response.item.children.length > 0 || response.item.parent_id != null) {
        $('.plannerwall-modal').addClass('plannerwall-modal--date-span');
      }
      if(response.item.invoiced){
        $('.plannerwall-modal').addClass('plannerwall-modal--invoiced');
      }

    }

    if(new_project){
      fn.display_new_project(target)
    }else{
      fn.display_project();
    }

  })
  .catch(err => {
    console.log(err);
    location.reload(true);
  });
}

export function new_project_card(link){
  return fetch(link, {
    method: 'get', 
    credentials: "same-origin",
  })
  .then(response => response.json())
  .then(response => {
    $('.plannerwall-week__item').each(function(){
      if($(this).data('model') == 'ProjectContainer' && $(this).attr('data-model-id') == response.item.project_container_id){
        let html = $(response.html).addClass('animated fadeIn');
        $(html).on("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function(event) {
          $(html).removeClass('animated fadeIn');
          $(html).off();
        });

        if($('.plannerwall-project--' + response.item.project_type + '-type', this).length > 0){
          $('.plannerwall-project--' + response.item.project_type + '-type', this).last().after(html);
          return false
        }else{
          $('.plannerwall-week__projects', this).append(html);
          return false
        }
      } else if($(this).data('date') == response.item.date){
        let html = $(response.html).addClass('animated fadeIn');
        $(html).on("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function(event) {
          $(html).removeClass('animated fadeIn');
          $(html).off();
        });

        if($('.plannerwall-project--' + response.item.project_type + '-type', this).length > 0){
          $('.plannerwall-project--' + response.item.project_type + '-type', this).last().after(html);
          return false
        }else{
          $('.plannerwall-week__new-project', this).before(html);
          return false
        }
      }
    })
  })
  .catch(err => {
    console.log(err);
    location.reload(true);
  });
}

export function update_project_card(link, old_item = null){
  return fetch(link, {
    method: 'get', 
    credentials: "same-origin",
  })
  .then(response => response.json())
  .then(response => {
    if ($('.plannerwall-project#project-' + response.item.id).parents('.plannerwall-week__item').attr('data-date') == response.item.date ) {
      $('.plannerwall-project#project-' + response.item.id).replaceWith(response.html);
    }else if($('.plannerwall-week__item[data-date="'+response.item.date+'"]').length > 0){
      if($('.plannerwall-project#project-' + response.item.id).length < 1){
        $(response.html).insertBefore($('.plannerwall-week__item[data-date="'+response.item.date+'"] .plannerwall-week__new-project'))
      }else{
        $('.plannerwall-project#project-' + response.item.id).detach().insertBefore($('.plannerwall-week__item[data-date="'+response.item.date+'"] .plannerwall-week__new-project')).replaceWith(response.html);
        fn.persons_check($('.plannerwall-week__item[data-date="'+old_item.date+'"] .plannerwall-week__projects'))
      }
    }else{
      $('.plannerwall-project#project-' + response.item.id).remove();
    }

    fn.persons_check($('.plannerwall-project#project-' + response.item.id).parents('.plannerwall-week__projects'))
    update_resources($('.plannerwall-week__item[data-date="'+response.item.date+'"]'))
  })
  .catch(err => {
    console.log(err);
    location.reload(true);
  });
}

export function copy_project(link, data = {}){
  return update_project_date(link, data)
}

export function copy_user(link, data = {}){
  return update_project_date(link, data)
}

export function update_project_date(link, data = {}){
  var token = $('meta[name="csrf-token"]').attr('content');
  return fetch(link, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token
    },
    credentials: "same-origin",
    body: JSON.stringify(data)
  })
  .then(response => response.json())
  .then(response => {
    return response;
  })
  .catch(err => {
    console.log(err);
    location.reload(true);
  });
}

export function update_projects_order(link, data = {}){
  var token = $('meta[name="csrf-token"]').attr('content');
  return fetch(link, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token
    },
    credentials: "same-origin",
    body: JSON.stringify(data)
  })
  .then(response => response.json())
  .then(response => {
  })
  .catch(err => {
    console.log(err);
    location.reload(true);
  });
}

export function move_association(link, data = {}){
  var token = $('meta[name="csrf-token"]').attr('content');
  return fetch(link, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token
    },
    credentials: "same-origin",
    body: JSON.stringify(data)
  })
  .then(response => response.json())
  .then(response => {
  })
  .catch(err => {
    console.log(err);
    location.reload(true);
  });
}

export function update_resources(container){
  let date = container.data('date');
  let link = '/ajax/dashboard/resources/'+date
  let token = $('meta[name="csrf-token"]').attr('content');

  return fetch(link, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token
    },
    credentials: "same-origin",
  })
  .then(response => response.json())
  .then(response => {
    $('.plannerwall-week__resources', container).replaceWith(response.html)
  })
  .catch(err => {
    console.log(err);
  });
}
