const ActionCable = require('actioncable-modules');
const cable = ActionCable.createConsumer();

// TODO: Fix this serverside and uncomment this!
// cable.subscriptions.create("TvChannel", {
//   connected() {
//     // Called when the subscription is ready for use on the server
//   },
//
//   disconnected() {
//     // Called when the subscription has been terminated by the server
//   },
//
//   received(data) {
//     if(data.refresh){
//       location.reload();
//     }
//   }
// });
