export function getDateOfWeek(w, y) {
    var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week

    return new Date(y, 0, d);
}

export function scrollToCurrent(){
  if($('.plannerwall-overview__week-number--current').length > 0){
    let week_offset = $('.plannerwall-overview__week-number--current').offset().top - $('.plannerwall-header').outerHeight() - $('.plannerwall-overview__header').outerHeight();
    if( $(window).scrollTop() < week_offset){
      $('html, body').animate({
        scrollTop: week_offset
      }, 800)
    }
  }
}