import Pikaday from 'pikaday';
import moment from 'moment';

$(() => {
  $('select').select2({
    language: "sv",
    minimumResultsForSearch: -1
  });

  $('.plannerwall-form__title').on('click', function(){
    if($(this).data('dropdown')){
      $(this).siblings('.plannerwall-form__container').slideToggle()
    }
  })

  let picker = new Pikaday({ 
    field: $('.plannerwall-form__input--datefield')[0],
    firstDay: 1,
    format: 'YYYY-MM-DD',
    i18n: {
      months        : moment.localeData('sv')._months,
      weekdays      : moment.localeData('sv')._weekdays,
      weekdaysShort : moment.localeData('sv')._weekdaysShort
    }
  });

});