import "core-js/stable";
import 'whatwg-fetch'

import Rails from '@rails/ujs';
Rails.start();

import "app";
import "dashboard";
import "tv";
import "overview";
