import * as fn from "./functions.js";
import * as formAjax from "./ajax.js";

import {Sortable, Plugins} from '@shopify/draggable/lib/es5/draggable.bundle.legacy';
import Pikaday from 'pikaday';
import moment from 'moment';
import timepicker from 'jquery-timepicker/jquery.timepicker.js';
import 'jquery-confirm';

// If Shift is pressed
let shiftPressed = false;
let shiftCode = 16;
let person_drag = false;
let vehicle_drag = false;
let draging = false;

$(document).on("keyup keydown", function(e) {
  switch(e.type) {
    case "keydown" :
      shiftPressed = e.shiftKey;
      if (shiftPressed) {
        if (person_drag) {
          $('.plannerwall-project__person.draggable--original').css('display', 'block');
        }else if (vehicle_drag) {
          /* 
           * uncomment this if you want copy function where it will display on original
           * project while holding down shift
           */
          //$('.plannerwall-project__vehicle.draggable--original').css('display', 'block'); 
        }else{
          $('.plannerwall-project.draggable--original').css('display', 'block');
        }
      }
      break;
    case "keyup" :
      shiftPressed = false;
      if (person_drag) {
        $('.plannerwall-project__person.draggable--original').css('display', 'none');
      }else if (vehicle_drag) {
        $('.plannerwall-project__vehicle.draggable--original').css('display', 'none');
      }else{
        $('.plannerwall-project.draggable--original').css('display', 'none');
      }
      break;
  }
});

function matchCustom(params, data) {
  // If there are no search terms, return all of the data
  if ($.trim(params.term) === '') {
    return data;
  }

  // Do not display the item if there is no 'text' property
  if (typeof data.text === 'undefined') {
    return null;
  }

  if($(data.element).hasClass('select2-selection__choice--inactivated')){
    return null;
  }

  // `params.term` should be the term that is used for searching
  // `data.text` is the text that is displayed for the data object
  if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
    return data;
  }

  return null;
}


const select2_options = {
  language: "sv",
  minimumResultsForSearch: -1,
  matcher: matchCustom,
  templateSelection: function (data, container) {
    let html = "";
    if (data.element) {
      $(container).addClass($(data.element).attr("class"));
      if($(data.element).attr('data-multi-projects') >= 2 ){
        if($(container).hasClass('select2-selection__choice--vehicle')){
          $(container).addClass('select2-selection__choice--vehicle-multi');
          html = $("<span class='select2-selection__choice__text' title='Detta fordon finns redan i ett projekt på detta datum.'><i class='fas fa-exclamation-triangle select2-selection__choice__multi-date'></i>" + data.text + '</span>');
        }else{
          html = $("<span class='select2-selection__choice__text' title='Personen finns redan i ett projekt på detta datum.'><i class='fas fa-exclamation-triangle select2-selection__choice__multi-date'></i>" + data.text + '</span>');
        }
      }else{
        html = $("<span class='select2-selection__choice__text'>" + data.text + '</span>');
      }
    }else{
      html = $("<span class='select2-selection__choice__text'>" + data.text + '</span>');
    }

    
    return html;
  },
  templateResult: function (data, container) {
    let html = "";
    if (data.element) {
      if(($(data.element).attr('data-multi-projects') >= 1 && data.selected == false) || $(data.element).attr('data-multi-projects') >= 2){
        $(container).addClass($(data.element).attr("class").replace(/-selection__choice/g, '-results__option'));
        if($(container).hasClass('select2-results__option--vehicle')){
          $(container).addClass('select2-results__option--vehicle-multi');
          html = $("<span class='select2-results__option__text' title='Detta fordon finns redan i ett projekt på detta datum.'><i class='fas fa-exclamation-triangle select2-results__option__multi-date'></i> " + data.text + '</span>');
        }else{
          html = $("<span class='select2-results__option__text' title='Personen finns redan i ett projekt på detta datum.'><i class='fas fa-exclamation-triangle select2-results__option__multi-date'></i> " + data.text + '</span>');
        }
      }else{
        html = $("<span class='select2-results__option__text'>" + data.text + '</span>');
      }
    }else{
      html = $("<span class='select2-results__option__text'>" + data.text + '</span>');
    }

    
    return html;
  },
}

export function projectModal (){

  $('select').each(function(){
    let classes = $(this).attr('class');
    let container = $(this);
    $.when( $(this).select2(select2_options) ).then(function(){
      container.siblings('.select2').addClass(classes);
    })
  })

  $('input.plannerwall-modal-form__timepicker').timepicker({
    zindex: '2',
    timeFormat: 'HH:mm',
    interval: 60,
    dynamic: false,
    change: function(time) {
      $('.plannerwall-modal-form').addClass('plannerwall-modal-form--changed');
      $('.plannerwall-modal-form__buttons--save').attr('data-save-popup', 'true');
    }
  });

  $('select').on('select2:open', function( event ) {
    setTimeout(function(){
      $('.select2-container .select2-dropdown .select2-results__option').each(function(){
        if($(this).hasClass('select2-selection__choice--inactivated')){
          $(this).remove();
        }
      })
    }, 0);
  });

  $("select").on("select2:unselect", function (evt) {
    if (!evt.params.originalEvent) {
      return;
    }

    evt.params.originalEvent.stopPropagation();
  });

  $('.plannerwall-modal-form__field--name').autocomplete({
    field: "name"
  });

  $('.plannerwall-modal-form__field--location').autocomplete({
    field: "location"
  });

  $.when( $('.plannerwall-modal-form__personnel-select').select2(select2_options) ).done(function(x){
    $('.select2-search input', x.siblings('.select2')).attr('placeholder', x.data('placeholder'));
    x.on('select2:select select2:unselect', function (e) {
      $('.select2-search input', x.siblings('.select2')).attr('placeholder', x.data('placeholder'));
    });
  })

  $.when( $('.plannerwall-modal-form__vehicles-select').select2(select2_options) ).done(function(x){
    $('.select2-search input', x.siblings('.select2')).attr('placeholder', x.data('placeholder'));
    x.on('select2:select select2:unselect', function (e) {
      $('.select2-search input', x.siblings('.select2')).attr('placeholder', x.data('placeholder'));
    });
  })

  $('.plannerwall-modal-form__datepicker-placeholder').on('input change', function(){
    let year = moment($(this).attr('data-date')).format('YYYY')
    let month = moment($(this).val(), 'DD MMM').format('MM')
    let day = moment($(this).val(), 'DD MMM').format('DD')
    let date = year + "-" + month + "-" + day
    $(this).siblings('input[name="'+$(this).attr('name').replace(/(-placeholder)/ig, '')+'"]').val(date);
  })

  let element = [];
  let picker = [];
  let hideFun;
  $('.plannerwall-modal-form__datepicker-placeholder').each(function(i, val){
    let selectedDates = [];
    element[i] = $(val);
    let field = (element[i].attr('name').replace(/(-placeholder)/ig, '').replace('project[', '').replace(']', ''));

    picker[i] = new Pikaday({ 
      field: this,
      firstDay: 1,
      format: 'DD MMM',
      theme: field,
      i18n: {
        months        : moment.localeData('sv')._months,
        weekdays      : moment.localeData('sv')._weekdays,
        weekdaysShort : moment.localeData('sv')._weekdaysShort
      },
      onOpen: function(date) {
        this.gotoDate(moment(element[i].siblings('input[name="'+element[i].attr('name').replace(/(-placeholder)/ig, '')+'"]').val()).toDate());
      },
      onSelect: function(date) {
        element[i].siblings('input[name="'+element[i].attr('name').replace(/(-placeholder)/ig, '')+'"]').val(moment(date).format('YYYY-MM-DD'))
        element[i].attr('data-date', moment(date).format('YYYY-MM-DD'));

        if (element[i].attr('name').replace(/(-placeholder)/ig, '') == "project[date_stop]"){
          element[i].siblings('.plannerwall-modal-form__datepicker-devider').addClass('plannerwall-modal-form__datepicker-devider--active');
        }

        if(i == 0 && picker.length > 1){
          picker[1].config({minDate: moment(date).toDate()})
          picker[1].gotoDate(moment(date).toDate());
          element[i].siblings('.plannerwall-modal-form__datepicker-devider').addClass('plannerwall-modal-form__datepicker-devider--active');
          picker[1].show();
        }
      },
      onClose: function(){
        if(i == 1 && this.getDate() == null){
          element[i].siblings('.plannerwall-modal-form__datepicker-devider').removeClass('plannerwall-modal-form__datepicker-devider--active');
        }
      }
    });

    picker[i].setDate(moment(element[i].siblings('input[name="'+element[i].attr('name').replace(/(-placeholder)/ig, '')+'"]').val()).toDate())
  });

  $('.plannerwall-modal-form input, .plannerwall-modal-form select, .plannerwall-modal-form textarea').on('change', function(){
    console.log(this);
    if(!$(this).hasClass('plannerwall-modal-form__datepicker-placeholder') && $(this).attr('name') != "project[date]" && $(this).attr('name') != "project[date_stop]"){
      $('.plannerwall-modal-form').addClass('plannerwall-modal-form--changed');
      $('.plannerwall-modal-form__buttons--save').attr('data-save-popup', 'true');
    }
    $('.plannerwall-modal-form').addClass('plannerwall-modal-form--changed');
  })

  $('.plannerwall-modal-form__input').on('change input', function(){
    if($(this).data('init-value') != $(this).val()){
      $('.plannerwall-modal-form').addClass('plannerwall-modal-form--changed');
    }else{
      $('.plannerwall-modal-form').removeClass('plannerwall-modal-form--changed');
    }
  })

  $('.plannerwall-modal-form__checkbox-input').on('change', function(){
    if($(this).is(":checked")){
      $('.plannerwall-modal').addClass('plannerwall-modal--invoiced');
    }else{
      $('.plannerwall-modal').removeClass('plannerwall-modal--invoiced');
    }
  });

  $('.plannerwall-modal-form__selectbox[name="project[project_type]"]').on('change', function(){
    let classes = $('.plannerwall-modal').attr('class').split(" ");
    let match = classes.find(value => /plannerwall-modal--(.*?)-type/.test(value));
    $('.plannerwall-modal').removeClass(match)
    $('.plannerwall-modal').addClass('plannerwall-modal--' + $(this).val() + '-type')

    classes = $('.plannerwall-modal-form__project-indicator').attr('class').split(" ");
    match = classes.find(value => /plannerwall-modal-form__project-indicator--(.*?)/.test(value));
    $('.plannerwall-modal-form__project-indicator').removeClass(match)
    $('.plannerwall-modal-form__project-indicator').addClass('plannerwall-modal-form__project-indicator--' + $(this).val())
  })

  $('.plannerwall-modal-form__field-container').on('click', function (e) {
    if(e.originalEvent != null && $('input',this).length < 2 && $('.plannerwall-modal-form__input',this).length > 0){
      let input = $('.plannerwall-modal-form__input',this);
      var len = input.val().length;
      input[0].focus();
      input[0].setSelectionRange(len, len);
    }else if (e.originalEvent != null && $('input',this).length < 2) {
      $('select',this).select2('open');
      $('input',this).click();   
    }else if(e.originalEvent != null && $('input',this).length > 1 && ($(e.originalEvent.target).hasClass('plannerwall-modal-form__field-label') || $(e.originalEvent.target).hasClass('plannerwall-modal-form__field-container'))){
      $('input',this)[0].click();
    }
  });

  // Handeling modal forms
  $('.plannerwall-modal-form').on('submit', fn.form_submit);
  $('.plannerwall-modal-form').on('ajax:error', formAjax.failed);
  if (document.querySelector(".plannerwall-modal-form") != null ) {
    document.querySelector(".plannerwall-modal-form").addEventListener('ajax:error', function(event) {
      var data = event.detail[0];
      formAjax.failed(event, data);
    });
    document.querySelector(".plannerwall-modal-form").addEventListener('ajax:success', function(event) {
      var data = event.detail[0];
      formAjax.success(event, data);
    });
  }

  $('.plannerwall-modal-form__field-label--name').on('click', function(e){
    fn.toggle_input(this, 'name');
  });

  $('.plannerwall-modal-form__field-label--location').on('click', function(e){
    fn.toggle_input(this, 'location');
  });

  $('.plannerwall-modal-form__buttons--save').on('click', function(e){
    if($('.plannerwall-modal').hasClass('plannerwall-modal--date-span')){
      if($(this).attr('data-save-popup') == "true"){
        e.preventDefault();
        let button = this;

        $.confirm({
          useBootstrap: false,
          draggable: true,
          title: '',
          content: 'Hur vill du tillämpa dessa förändringar?',
          theme: 'light',
          boxWidth: '450px',
          closeIcon: true,
          type: 'red',
          buttons: {
            "Denna dag": function () {
              console.log($(button).parents('.plannerwall-modal-form'));
              $(button).parents('.plannerwall-modal-form').submit();
            },
            "Denna dag och framåt": function () {
              $('input[name="project[update_farward]"]').val(1)
              $(button).parents('.plannerwall-modal-form').submit();
            },
            "Hela projektet": function () {
              $('input[name="project[update_all]"]').val(1)
              $(button).parents('.plannerwall-modal-form').submit();
            }
          }
        });
      }
    }
  })

  $('.plannerwall-modal-form__buttons--remove').on('ajax:before', function(e){
    if($('.plannerwall-modal').hasClass('plannerwall-modal--date-span') && $(this).attr('data-delete') == null){
      return false;
    }
  });

  $('.plannerwall-modal-form__buttons--remove').on('click', function(e){
    if($('.plannerwall-modal').hasClass('plannerwall-modal--date-span') && $(this).attr('data-delete') == null){
      e.preventDefault();
      let button = this;

      $.confirm({
        useBootstrap: false,
        draggable: true,
        title: '',
        content: 'Hur vill du tillämpa dessa förändringar?',
        theme: 'light',
        boxWidth: '450px',
        closeIcon: true,
        type: 'red',
        buttons: {
          "Denna dag": function () {
            // console.log($(button).parents('.plannerwall-modal-form'));
            $(button).attr('data-delete', '0');
            $(button).attr('href', $(button).attr('href')+"/?delete=0");
            $(button).click();
          },
          "Denna dag och framåt": function () {
            $(button).attr('data-delete', '1');
            $(button).attr('href', $(button).attr('href')+"/?delete=1");
            $(button).click();
          },
          "Hela projektet": function () {
            $(button).attr('data-delete', '2');
            $(button).attr('href', $(button).attr('href')+"/?delete=2");
            $(button).click();
          }
        }
      });
    }
  })

  $('.plannerwall-modal-form__buttons--cancel').on('confirm:complete', function(e, answer){
    e.preventDefault();
    if (answer) { 
      fn.hide_project();
    }
  });

  $('.plannerwall-modal-form__buttons--close').on('click', function(e){
    e.preventDefault();
    fn.hide_project();
  });
}


export function sort_projects() {
  const containers = document.querySelectorAll('.plannerwall-week__projects');

  if (containers.length === 0) {
    return false;
  }

  const sortable_projects = new Sortable(containers, {
    draggable: '.plannerwall-project:not(.plannerwall-project--date-span)',
    delay: 300,
    mirror: {
      constrainDimensions: true,
    },
    swapAnimation: {
        duration: 200,
        easingFunction: 'ease-in-out'
      },
      plugins: [Plugins.SwapAnimation]
  });


  // --- Draggable events --- //
  sortable_projects.on('drag:start', (evt) => {
    let height = $(evt.data.mirror).outerHeight();
    $(evt.data.source).css('min-height', height);
    draging = true;
  });

  sortable_projects.on('drag:move', (evt) => {
    $('.plannerwall-week__new-project').each(function(){
      $(this).appendTo($(this).parent());
    });
  });

  sortable_projects.on('mirror:created', (evt) => {
    let original_height = $(evt.data.originalSource).outerHeight();
    $(evt.mirror).css('min-height', original_height+"px");
  });

  sortable_projects.on('sortable:stop', (evt) => {
    evt = evt.dragEvent;
    if (shiftPressed) { // If Shift is pressed
      $('.plannerwall-project.draggable--original').clone().addClass('project-copied').removeClass('draggable--original').insertBefore($('.plannerwall-project.draggable--original'))
    }

    setTimeout(() => {
      let source = $(evt.data.source)
      let data = {
        id: source.attr('id').split('-').pop(),
        new_date: $('#'+source.attr('id')+':not(.project-copied)').parent().parent().data('date'),
        new_model: false,
        models: []
      }

      $('.plannerwall-week__item--tab').each(function(){
        if ($.inArray($(this).attr('data-model'), data['models']) === -1){
          data['models'].push($(this).attr('data-model'));
        }
      });

      if ( $('#'+source.attr('id')+':not(.project-copied)').parent().parent()[0].hasAttribute('data-model')) {
        data['new_model'] = {
          model: $('#'+source.attr('id')+':not(.project-copied)').parent().parent().attr('data-model'),
          model_id: $('#'+source.attr('id')+':not(.project-copied)').parent().parent().attr('data-model-id')
        }
      }

      if (shiftPressed) { // If Shift is pressed
        $.when(formAjax.copy_project('/projects/copy_project', data)).then(function(data, textStatus, jqXHR){
          $('#'+source.attr('id')+':not(.project-copied)').attr('id', 'project-'+data.item.id).attr('data-remote-url', data.urls.edit);

          let ids = [];
          $('.plannerwall-project:not(.draggable-mirror):not(.draggable-source--is-dragging)', $('#'+source.attr('id')).parent().parent()).each(function(){
            ids.push($(this).attr('id').split('-').pop());
          })

          data = { ids: ids }
          formAjax.update_projects_order('/projects/update_orders', data)

          formAjax.update_resources($(evt.data.originalSource).parents('.plannerwall-week__item'))
          formAjax.update_resources($(evt.data.sourceContainer).parents('.plannerwall-week__item'))

          $('.plannerwall-project').removeClass('project-copied');
        });
      }else{
        formAjax.update_project_date('/projects/update_date', data)

        let ids = [];
        $('.plannerwall-project:not(.draggable-mirror):not(.draggable-source--is-dragging)', $('#'+source.attr('id')).parent().parent()).each(function(){
          ids.push($(this).attr('id').split('-').pop());
        })

        data = { ids: ids }
        $.when(formAjax.update_projects_order('/projects/update_orders', data)).then(function(){
          formAjax.update_resources($(evt.data.originalSource).parents('.plannerwall-week__item'))
          formAjax.update_resources($(evt.data.sourceContainer).parents('.plannerwall-week__item'))
        })
      }

      fn.persons_check($(evt.data.originalSource).parents('.plannerwall-week__projects'));
      fn.persons_check($(evt.data.sourceContainer));
    }, 0)

    draging = false;
  });

  

  return sortable_projects;
}

export function sort_personnel() {
  const containers = document.querySelectorAll('.plannerwall-project__personnel');

  if (containers.length === 0) {
    return false;
  }

  const sortable_personnel = new Sortable(containers, {
    draggable: '.plannerwall-project__person',
    delay: 200,
    mirror: {
      constrainDimensions: true,
    },
    swapAnimation: {
        duration: 200,
        easingFunction: 'ease-in-out'
      },
      plugins: [Plugins.SwapAnimation]
  });


  // --- Draggable events --- //


  sortable_personnel.on('drag:start', (evt) => {
    window.project_sort.on('drag:start', (evt) => {
     evt.cancel();
    });
    $('.plannerwall-project.draggable--original').removeClass('draggable--original');
    draging = true;
    person_drag = true;
  });

  sortable_personnel.on('sortable:stop', (evt) => {
    evt = evt.dragEvent;
    window.project_sort.destroy();
    window.project_sort = sort_projects();

    setTimeout(() => {
      let source = $(evt.data.originalSource);
      let old_container = $(evt.data.sourceContainer);
      let person = $(evt.data.source)

      let data = {
        person: person.attr('data-id').split('-').pop(),
        moved_from: old_container.parents('.plannerwall-project').attr('id').split('-').pop(),
        moved_to: source.parents('.plannerwall-project').attr('id').split('-').pop(),
      }

      if (shiftPressed) { // If Shift is pressed
        $.when(formAjax.copy_user('/projects/copy_user', data)).then(function(data, textStatus, jqXHR){
          formAjax.update_resources($(evt.data.originalSource).parents('.plannerwall-week__item'))
          formAjax.update_resources($(evt.data.sourceContainer).parents('.plannerwall-week__item'))
        });
        $(source).clone().appendTo(old_container);
        
        if($('.plannerwall-project__person[data-id="'+source.attr('data-id')+'"]', source.parents('.plannerwall-project')).length > 1){
          alert("Personen finns redan på det här projektet.");
          $(source).remove();
        }
      }else{
        
        if($('.plannerwall-project__person[data-id="'+source.attr('data-id')+'"]', source.parents('.plannerwall-project')).length > 1){
          if (confirm("Personen du försöker flytta finns redan på projektet. Vid flytt så kommer ena taggen försvinna.\n\n Vill du fortsätta?")){
            source.remove();
            $.when(formAjax.move_association('/projects/move_person', data)).then(function(){
              formAjax.update_resources(old_container.parents('.plannerwall-week__item'))
              formAjax.update_resources(source.parents('.plannerwall-week__item'))
            })
          }else{
            old_container.append(source);
          }
        }else{
          $.when(formAjax.move_association('/projects/move_person', data)).then(function(){
            formAjax.update_resources(old_container.parents('.plannerwall-week__item'))
            formAjax.update_resources(source.parents('.plannerwall-week__item'))
          })
        }
      }

      fn.persons_check(source.parents('.plannerwall-week__projects'))
      fn.persons_check(old_container.parents('.plannerwall-week__projects'))
    }, 0)
    $('.plannerwall-project.draggable--original').removeClass('draggable--original');

    draging = false;
    person_drag = false;
  });

  return sortable_personnel;
}

export function sort_vehicles() {
  const containers = document.querySelectorAll('.plannerwall-project__vehicles');

  if (containers.length === 0) {
    return false;
  }

  const sortable_vehicles = new Sortable(containers, {
    draggable: '.plannerwall-project__vehicle',
    delay: 200,
    mirror: {
      constrainDimensions: true,
      cursorOffsetX: 17,
    },
    swapAnimation: {
        duration: 200,
        easingFunction: 'ease-in-out'
      },
      plugins: [Plugins.SwapAnimation]
  });


  // --- Draggable events --- //
  sortable_vehicles.on('drag:start', (evt) => {
    window.project_sort.on('drag:start', (evt) => {
     evt.cancel();
    });
    $('.plannerwall-project.draggable--original').removeClass('draggable--original');
    draging = true;
    vehicle_drag = true;
  });

  sortable_vehicles.on('sortable:stop', (evt) => {
    evt = evt.dragEvent;
    window.project_sort.destroy();
    window.project_sort = sort_projects();

    setTimeout(() => {
      let source = $(evt.data.originalSource);
      let old_container = $(evt.data.sourceContainer);
      let person = $(evt.data.source)


      let data = {
        vehicle: person.attr('data-id').split('-').pop(),
        moved_from: old_container.parents('.plannerwall-project').attr('id').split('-').pop(),
        moved_to: source.parents('.plannerwall-project').attr('id').split('-').pop(),
      }

      if($('.plannerwall-project__vehicle[data-id="'+source.attr('data-id')+'"]', source.parents('.plannerwall-project')).length > 1){
        if (confirm("Fordonet du försöker flytta finns redan på projektet. Vid flytt så kommer ena taggen försvinna.\n\n Vill du fortsätta?")){
          source.remove();
          $.when(formAjax.move_association('/projects/move_vehicle', data)).then(function(){
            formAjax.update_resources(old_container.parents('.plannerwall-week__item'))
            formAjax.update_resources(source.parents('.plannerwall-week__item'))
          })
        }else{
          old_container.append(source);
        }
      }else{
        $.when(formAjax.move_association('/projects/move_vehicle', data)).then(function(){
          formAjax.update_resources(old_container.parents('.plannerwall-week__item'))
          formAjax.update_resources(source.parents('.plannerwall-week__item'))
        })
      }
    }, 0)
    $('.plannerwall-project.draggable--original').removeClass('draggable--original');

    draging = false;
    vehicle_drag = false;
  });

  return sortable_vehicles;
}

export function weekSelect(){
  var field = document.getElementById('header_dateSelect');
  var header_picker = new Pikaday({
    field: field,
    trigger: $('.plannerwall-header__title-container > span')[0],
    container: $('.plannerwall-header__title-container > span')[0],
    showWeekNumber: true,
    format: 'YYYY/WW',
    onSelect: function(date) {
      window.location.href = $(field).data('dashboardurl') + "/" + header_picker.getMoment().format('YYYY/WW');
      return;
    }
  });
}
