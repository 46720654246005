import * as fn from "./functions.js";

let loop = init_loop();

$('.plannerwall-header__page-title--next-week').on('click', function(){
  clearInterval(loop);
  fn.toggle_next_week();
  loop = init_loop();
})

$('.plannerwall-header__page-title--this-week').on('click', function(){
  clearInterval(loop);
  fn.toggle_prev_week();
  loop = init_loop();
})

function init_loop(){
  return fn.loop(40, function(width, duration){
    if(width == 15){
      $('.plannerwall-week--active .plannerwall-week__item .plannerwall-week__projects').each(function(){
        $(this).animate({
          scrollTop: $(this)[0].scrollHeight - $(this)[0].clientHeight
        }, ((duration / 100) * 70) * 1000);
      });
    }
  });
}