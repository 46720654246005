window.jQuery = window.$ = require('jquery/dist/jquery.min.js');

$(function() {
  require('./cable.js')
  if($('.body--tv-day').length > 0){
    require('./day.js');
  }

  if($('.body--tv-week').length > 0){
    require('./week.js');
  }
});