import * as init from "./initializers.js";


export function display_new_project(element) {
  $('.plannerwall-modal .plannerwall-modal-form__datepicker').val($(element).data('date'));
  display_project();
}

export function display_project() {
  $('.plannerwall-modal-overlay').addClass('plannerwall-modal-overlay--open animated fadeIn');
  $('.plannerwall-modal-container').addClass('plannerwall-modal-container--open animated slideInDown');
}

export function hide_project(){
  $('.plannerwall-modal-overlay').addClass('plannerwall-modal-overlay--open animated fadeOut').on("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function(event) {
    $('.plannerwall-modal-overlay').removeClass('plannerwall-modal-overlay--open animated fadeOut fadeIn')
    $('.plannerwall-modal-container').removeClass('plannerwall-modal-container--open animated slideInDown slideOutDown')
    $('.pika-single').remove();
    init.weekSelect();
    $(this).off()
  });

  $('.plannerwall-modal-container').addClass('plannerwall-modal-container--open animated slideOutDown');
}

export function hide_project_card(data){
  $('.plannerwall-project#project-' + data.item.id).addClass('animated fadeOut').on("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function(event) {
    let projectContainer = $('.plannerwall-project#project-' + data.item.id).parents('.plannerwall-week__projects');
    $(this).remove();
    persons_check(projectContainer)
    $(this).off();
  });
}

export function form_submit(){
  $('[class*="--error"]').removeClass('plannerwall-modal-form__field-container--error');
  $('.plannerwall-moda-form__notice-container').html('');
}

export function toggle_input(target, input_name){
  $(target).addClass('plannerwall-modal-form__field-label--'+input_name).addClass('plannerwall-modal-form__field-label--hidden');
  $(target).siblings('.plannerwall-modal-form__field').addClass('plannerwall-modal-form__field--open').focus().on('focusout', function(){
    $(this).removeClass('plannerwall-modal-form__field--open');
    $(this).siblings('.plannerwall-modal-form__field-label--hidden').removeClass('plannerwall-modal-form__field-label--hidden');
  });

  $(target).siblings('.plannerwall-modal-form__field').on('change', function(){
    if($(this).val() == ""){
      $(target).html($(target).data('placeholder'))
    }else{
      $(target).html($(this).val());
      $(target).removeClass('plannerwall-modal-form__field-label--error')
    }
  })
}

export function searchProjects(value){
  $('.plannerwall-project').css("display", '');

  if(value != ""){
    $('.plannerwall-project').each(function(){
      if($('.plannerwall-project__name', this).text().toLowerCase().indexOf(value.toLowerCase()) > -1){
        return;
      }

      if($('.plannerwall-project__location', this).text().toLowerCase().indexOf(value.toLowerCase()) > -1){
        return;
      }

      let found = false;
      $('.plannerwall-project__person', this).each(function(){
        if($(this).attr('title').toLowerCase().indexOf(value.toLowerCase()) > -1 || $(this).text().toLowerCase().indexOf(value.toLowerCase()) > -1 || found){
          found = true;
          return;
        }
      });
      if (found) { return; }
       
      $(this).css('display', 'none');
    });
  }
}

export function persons_check(projectContainer){
  let warningContainer = $('.plannerwall-week__warning', $(projectContainer).parents('.plannerwall-week__item'));
  let warningPerson = $('svg', warningContainer).data('person');
  let warningUnavailable = $('svg', warningContainer).data('unavailable');
  let warningBoth = $('svg', warningContainer).data('both');
  let found = false

  if(projectContainer){
    $('.plannerwall-project__person', $(projectContainer)).each(function(){
      if($('.plannerwall-project__person[data-id='+$(this).attr('data-id')+"]", $(projectContainer)).length > 1){
        found = true
        $('.plannerwall-project__person[data-id='+$(this).attr('data-id')+"]", $(projectContainer)).addClass("plannerwall-project__person--warning");
        if(warningUnavailable){
          warningBoth = true
          warningPerson = false
          warningUnavailable = false
          $('svg', warningContainer).attr('data-both', 'true');
          $('svg', warningContainer).attr('data-unavailable', 'false');
          $('svg title', warningContainer).html($('svg', warningContainer).data('tempboth'));
        }else if(warningPerson == false || warningUnavailable == false || warningBoth == false){
          warningBoth = false
          warningPerson = true
          warningUnavailable = false
          $('svg', warningContainer).attr('data-person', 'true');
          $('svg title', warningContainer).html($('svg', warningContainer).data('tempperson'));
          $(warningContainer).addClass('plannerwall-week__warning--active');
        }
      }else if(found == false){
        $('.plannerwall-project__person[data-id='+$(this).attr('data-id')+"]", $(projectContainer)).removeClass("plannerwall-project__person--warning");
        warningBoth = false
        warningPerson = false
        warningUnavailable = false
        $(warningContainer).removeClass('plannerwall-week__warning--active');
        $('svg', warningContainer).attr('data-person', 'false');
        $('svg', warningContainer).attr('data-both', 'false');
        $('svg', warningContainer).attr('data-unavailable', 'false');
        $('svg title', warningContainer).html('');
      }
    })

    if($('.plannerwall-project__person.plannerwall-project__person--sick, .plannerwall-project__person.plannerwall-project__person--unavailable', $(projectContainer)).length > 0){
      if(warningPerson){
        warningBoth = true
        warningPerson = false
        warningUnavailable = false
        $('svg', warningContainer).attr('data-both', 'true');
        $('svg', warningContainer).attr('data-person', 'false');
        $('svg title', warningContainer).html($('svg', warningContainer).data('tempboth'));
      }else if(warningPerson == false || warningUnavailable == false || warningBoth == false){
        warningBoth = false
        warningPerson = false
        warningUnavailable = true
        $('svg', warningContainer).attr('data-unavailable', 'true');
        $('svg title', warningContainer).html($('svg', warningContainer).data('tempunavailable'));
        $(warningContainer).addClass('plannerwall-week__warning--active');
      }
    }
  }
}

$.fn.extend({
  autocomplete: function(options = {}) {
    let currentValue = $(this).val();

    $(this).attr('autocomplete', "off");

    $('.plannerwall-modal-form').on('click', '.plannerwall-modal-form__field-autocomplete div', function(){
      let value = $(this).html();
      $(this).parent().siblings('.plannerwall-modal-form__field').val(value);
      $(this).parent().siblings('.plannerwall-modal-form__field-label').html(value);
      $('.plannerwall-modal-form__field-autocomplete').remove();
    })

    $(this).on('keyup', function(){
      let input = this;

      if( $(input).val() != "" ){
        if (currentValue != (currentValue = $(input).val())) {
          let term = $(input).val();

          $.ajax({
            url: '/projects',
            data: {
              term: term,
              field: options.field
            },
            success: function(data){
              if(data.items.length > 0){          
                if($('.plannerwall-modal-form__field-autocomplete').length == 0){
                  $(input).after("<div class='plannerwall-modal-form__field-autocomplete plannerwall-modal-form__field-autocomplete--" + options.field + "' style='top:" + $(input).height() + "px;'></div>")
                }

                let data_output = data.items.map(obj =>{
                  return obj[options.field]
                });

                $(data.items).each(function(){
                  let found = false;
                  let item = this

                  $('.plannerwall-modal-form__field-autocomplete div').each(function(){
                    if($(this).html() == item[options.field]){ found = true }
                    if($.inArray($(this).html(), data_output) == -1){ $(this).remove() }
                  })

                  if(found == false){
                    $('.plannerwall-modal-form__field-autocomplete').append('<div>'+ this[options.field] +'</div>')
                  }
                })
              }else{
                $('.plannerwall-modal-form__field-autocomplete').remove();
              }
            }
          })
        }
      }else{
        $('.plannerwall-modal-form__field-autocomplete').remove();
      }
    })
  }
});

