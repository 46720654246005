import * as fn from "./functions.js";

fn.loop(40, function(width, duration){
  if(width == 10){
    $('.plannerwall-week__item .plannerwall-week__projects').each(function(){
      $(this).animate({
        scrollTop: $(this)[0].scrollHeight - $(this)[0].clientHeight
      }, ((duration / 100) * 40) * 1000);
    });
  }else if(width == 50){
    $('.plannerwall-week__item .plannerwall-week__projects').each(function(){
      $(this).animate({
        scrollTop: '0'
      }, ((duration / 100) * 40) * 1000);
    });
  }
});

$('.plannerwall-project__personnel').each(function( index ) {
  if($('.plannerwall-project__person', this).length <= 6){
    var lastChild = $(this).children().last();
    var newWidth = ($(this).position().left + $(this).outerWidth(true)) - (lastChild.position().left) + 31;
    $(this).width(newWidth);
  }
});

$('.plannerwall-project__vehicles').each(function( index ) {
    var lastChild = $(this).children().last();
    var newWidth = ($(this).position().left + $(this).outerWidth(true)) - (lastChild.position().left) + 21;
    $(this).width(newWidth);
});