import Rails from '@rails/ujs';


//Rails.start();

let moment = require('moment');
let ClassPrefix = "plannerwall";

$(function() {
  if($('.plannerwall-form').length > 0){
    require("./forms.js")
  }

  if($('.plannerwall-header__clock').length > 0){
    function clock_update() {
      $('.plannerwall-header__clock').html(moment().format('H') + "<span class='plannerwall-header__clock-seperator'>:</span>" + moment().format('mm') + "<span class='plannerwall-header__clock-seperator'>:</span>" + moment().format('ss'));
    }

    setInterval(clock_update, 1000);
  }

});
