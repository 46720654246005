import * as ajax from "./ajax.js";
import * as fn from "./functions.js"
import moment from 'moment';

let working = false

$(function() {
  if($('body.body--overview').length > 0){
    
    fn.scrollToCurrent()

    $(window).on('scroll', function(){
      if($(window).scrollTop() > 1){
        $('.plannerwall-overview__header').addClass('plannerwall-overview__header--scrolling');
      }else{
        $('.plannerwall-overview__header').removeClass('plannerwall-overview__header--scrolling');
      }

      let window_scroll = $(window).scrollTop();
      let header_height = $('.plannerwall-header').height();
      let subheader_height = $('.plannerwall-overview__header').height()
      let first_project_current = $('.plannerwall-overview__week-link[data-week="1"]'+'[data-year="' + parseInt($('.plannerwall-header__page-title').text().replace(/(.*?) /, '')) + '"]')
      let last_project_current = $('.plannerwall-overview__week-link[data-week="52"]'+'[data-year="' + parseInt($('.plannerwall-header__page-title').text().replace(/(.*?) /, '')) + '"]')

      if(last_project_current.length > 0){
        if ( (window_scroll + header_height + subheader_height) > last_project_current.offset().top + last_project_current.outerHeight() ){
          $('.plannerwall-header__page-title').text($('.plannerwall-header__page-title').text().replace(/ (.{4})/, ' '+last_project_current.attr('data-next-year')))
        }
      }

      if(first_project_current.length > 0){
        if ( (window_scroll + header_height + subheader_height) < first_project_current.offset().top ){
          $('.plannerwall-header__page-title').text($('.plannerwall-header__page-title').text().replace(/ (.{4})/, ' '+(parseInt(first_project_current.attr('data-year'))-1)));
        }
      }

      if($(window).scrollTop() + $(window).height() > $(document).height() - $(window).height()) {
        if(!working){
          working = true
          let last_week = $('.plannerwall-overview__week-link:last-child')
          let year = last_week.attr('data-year')
          let start_date = fn.getDateOfWeek(parseInt(last_week.attr('data-week'))+1, year);
          let end_date = fn.getDateOfWeek(parseInt(last_week.attr('data-week'))+1, year)
          end_date.setDate(end_date.getDate()+(7*8));

          $.when(ajax.get_week_range('/ajax/overview/'+moment(start_date).format('YYYY-MM-DD')+'/'+moment(end_date).format('YYYY-MM-DD'))).then(function(){
            working = false
          })
        }
      }
    })
  }
});