require('select2');
let Pikaday = require('pikaday');
let moment = require('moment');
import * as fn from "./functions.js";
import * as formAjax from "./ajax.js";
import * as init from "./initializers.js";


$(function() {
  if($(window).width() < 1024){
    $('.plannerwall-week__item').each(function(){
      var date = moment($(this).data('date'));
      if(date.isSame(new Date(), "day")){
        $('html, body').animate({
          scrollTop: $(this).offset().top - $('.plannerwall-header').outerHeight()
        }, 800)
      }
    })

    $('.plannerwall-phone-subheader__name-container').on('click', function(e){
      if($(e.target).hasClass('plannerwall-phone-subheader__name')){
        $('.plannerwall-phone-subheader__dropdown', this).css('display', 'block')
      }
    })

    $('.plannerwall-phone-subheader__dropdown .plannerwall-phone-subheader__dropdown-option').on('click', function(){
      $('.plannerwall-phone-subheader__dropdown-option').removeClass('plannerwall-phone-subheader__dropdown-option--active');
      $(this).addClass('plannerwall-phone-subheader__dropdown-option--active');
      $('.plannerwall-phone-subheader__name' ,$(this).parents('.plannerwall-phone-subheader__name-container')).text($(this).text());
      if($(this).data('option')){
        $('.plannerwall-project').css('display', 'block');
      }else{
        $('.plannerwall-project').css('display', '');
      }

      $(this).parents('.plannerwall-phone-subheader__dropdown').css('display', '');
    })
  }

  if($('.plannerwall-week--admin').length > 0 && $('.body--dashboard').length > 0){
    window.project_sort = init.sort_projects();
    window.personnel_sort = init.sort_personnel();
    window.vehicles_sort = init.sort_vehicles();

    var field = document.getElementById('header_dateSelect');
    var header_picker = new Pikaday({
      field: field,
      trigger: $('.plannerwall-header__title-container > span')[0],
      container: $('.plannerwall-header__title-container > span')[0],
      showWeekNumber: true,
      format: 'YYYY/WW',
      firstDay: 1,
      i18n: {
        months        : moment.localeData('sv')._months,
        weekdays      : moment.localeData('sv')._weekdays,
        weekdaysShort : moment.localeData('sv')._weekdaysShort
      },
      onSelect: function(date) {
        window.location.href = $(field).data('dashboardurl') + "/" + header_picker.getMoment().format('YYYY/WW');
      }
    });
    
    init.projectModal();

    $('button[data-js="button_new_project"]').on('click', function(e){
      formAjax.open_project(this, true)
    })

    $('.plannerwall-modal-container').on('click', function(e){
      
      let close_button_clicked = false;
       $('.plannerwall-modal-form__close').find('*').each(function(){
          if (close_button_clicked == false)
            close_button_clicked = e.target == this;
       })

      if (e.target !== this && close_button_clicked == false){ return; }

      if($('.plannerwall-modal-form').hasClass('plannerwall-modal-form--changed')){
        if (confirm("Är du säker på att du vill avbryta? Dina ändringar kommer att gå förlorade.")){
          fn.hide_project();
        }
      }else{
        fn.hide_project();
      }
    })

    // Toggle project containers
    $('.plannerwall-subheader__expand-button').on('click', function(){
      $(this).toggleClass('plannerwall-subheader__expand-button--active');
      $('.'+$(this).attr('data-target')).toggleClass($(this).attr('data-target').replace(/--.*/, '')+'--open');
    })

    // When you click on an project
    $('body').on('click', '.plannerwall-project__edit-icon', function(e){
      e.preventDefault();
      formAjax.open_project(this);
    });

    $('body').on('click', '.plannerwall-project__order-given-icon', function(e){
      e.preventDefault();
      e.stopPropagation();

      let icon = $(this);
      let project = $(this).parents('.plannerwall-project')
      let id = project.attr('id').replace('project-', '');

      fetch('/ajax/project/order-given/'+id, {
        method: 'get', 
        credentials: "same-origin",
      })
      .then(response => response.json())
      .then(response => {
        if (response.status == true) {
          icon.addClass('plannerwall-project__order-given-icon--active')
        } else {
          icon.removeClass('plannerwall-project__order-given-icon--active')
        }
      })
    });

    $('body').on('click', '.plannerwall-project', function(e){
      formAjax.open_project(this);
    });

    $('#head-search').on('input', function(){
      fn.searchProjects($(this).val());
    });

    // Desciption icon open tab with description content
    $('body').on('click mouseenter', '.plannerwall-project__description-icon', function(e){
      e.stopPropagation();

      let project = $(this).parents('.plannerwall-project')
      let id = project.attr('id').replace('project-', '');
      fetch('/ajax/project/'+id, {
        method: 'get', 
        credentials: "same-origin",
      })
      .then(response => response.json())
      .then(response => {
        let html = $("<div class='plannerwall-project__description'></div>");
        html.html(response.description.replace(/(?:\r\n|\r|\n)/g, '<br>'));
        if(($(window).width() - $(this).offset().left) > 200) {
          html.css({ left: ( $(this).offset().left + $(this).outerWidth() )+"px", top: ( $(this).offset().top )+"px" })
        } else {
          html.css({ right: ($(this).offset().left) })
        }
        project.append(html);
      })
    });

    $('body').on('mouseleave', '.plannerwall-project__description-icon, .plannerwall-project__description', function(e){
      let project = $(this).parents('.plannerwall-project')

      if(!$(e.toElement).hasClass('plannerwall-project__description')) {
        $('.plannerwall-project__description', project).remove();
      }
    });

    $('body').on('click', '.plannerwall-checkbox__title', function(){
      $(this).siblings('.plannerwall-checkbox').click();
    })

    $('.plannerwall-header__sort-input').on('change', function(){
      let project_type = $(this).attr('id').replace('sort-', '')
      if( $(this).is(':checked') == false ){
        $('.plannerwall-project.plannerwall-project--' + project_type + '-type').css('display', 'none')
      }else{
        $('.plannerwall-project.plannerwall-project--' + project_type + '-type').css('display', '')
      }
    })
  }
});
