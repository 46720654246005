export function loop(duration = 20, callback = function(){}){
  let width = 0;
  let timer = 0;
  let current_week = true;
  let loop = 0;

  return setInterval(function(){
    if(width >= 100){
      width = 0;
      timer = 0;
      loop++;

      if (loop === 4) {
        location.reload();
      }

      $('.plannerwall-week__timer-progress').css('transition', 'none');
      $('.plannerwall-week__timer-progress').css('width', '');
      $('.plannerwall-week__timer-progress').css('transition', '');

      if(current_week){
        toggle_next_week();
        current_week = false;
      }else{
        toggle_prev_week();
        current_week = true;
      }
    }

    callback(width, duration);

    timer++
    width = (timer / duration) * 100
    
    $('.plannerwall-week__timer-progress').css('width', width+'%');
  }, 1000);
}

export function toggle_next_week(){
  $('.plannerwall-week--this-week').css({
    'margin-left': -+$(window).width()
  });

  $('.plannerwall-week--this-week').on("webkitTransitionEnd mozTransitionEnd MSTransitionEnd otransitionend transitionend", function(event) {
    $('.plannerwall-week--this-week .plannerwall-week__projects').each(function(){
      $(this).stop().scrollTop(0);
    })
    $(this).off();
  });

  $('.plannerwall-week--this-week').removeClass('plannerwall-week--active');
  $('.plannerwall-week--next-week').addClass('plannerwall-week--active');
  $('.plannerwall-header__page-title--next-week').removeClass('plannerwall-header__page-title--disabled')
  $('.plannerwall-header__page-title--next-week').siblings('.plannerwall-header__page-subtitle').removeClass('plannerwall-header__page-subtitle--disabled')
  $('.plannerwall-header__page-title--this-week').addClass('plannerwall-header__page-title--disabled')
  $('.plannerwall-header__page-title--this-week').siblings('.plannerwall-header__page-subtitle').addClass('plannerwall-header__page-subtitle--disabled')
}

export function toggle_prev_week(){
  $('.plannerwall-week--this-week').css({
    'margin-left': ''
  });

  $('.plannerwall-week--this-week').on("webkitTransitionEnd mozTransitionEnd MSTransitionEnd otransitionend transitionend", function(event) {
    $('.plannerwall-week--next-week .plannerwall-week__projects').each(function(){
      $(this).stop().scrollTop(0);
    })
    $(this).off();
  });

  $('.plannerwall-week--this-week').addClass('plannerwall-week--active');
  $('.plannerwall-week--next-week').removeClass('plannerwall-week--active');
  $('.plannerwall-header__page-title--next-week').addClass('plannerwall-header__page-title--disabled')
  $('.plannerwall-header__page-title--next-week').siblings('.plannerwall-header__page-subtitle').addClass('plannerwall-header__page-subtitle--disabled')
  $('.plannerwall-header__page-title--this-week').removeClass('plannerwall-header__page-title--disabled')
  $('.plannerwall-header__page-title--this-week').siblings('.plannerwall-header__page-subtitle').removeClass('plannerwall-header__page-subtitle--disabled')
}