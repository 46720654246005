import * as fn from "./functions.js"

export function get_week_range(link){
  return fetch(link, {
    method: 'get', 
    credentials: "same-origin",
  })
  .then(response => response.json())
  .then(response => {
    $.when($.each(response.html, function(key, week){
      if (week != null){
        $(week).appendTo($('.plannerwall-overview__body'));
      }
    })).then(fn.scrollToCurrent())
  })
  .catch(err => {
    console.log(err);
    location.reload(true);
  });
}